<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="content_margin_bottom">
      <div class="row">
        <div class="col-md-12 mb-4">
          <h4 class="card-title my-3 float-right">
            <router-link
              :to="
                `/entity/${$route.params.id}/course/${$route.params.courseId}`
              "
              class="btn btn-success btn-sm"
              >Course Details</router-link
            >
          </h4>
        </div>
      </div>
      <section>
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="card" v-if="step == 1">
              <div class="card-body">
                <div class="form-group">
                  <label for="title">Title</label>
                  <input
                    type="text"
                    placeholder="Enter title"
                    id="title"
                    class="form-control"
                    v-model="course.title"
                  />
                </div>
                <div class="form-group">
                  <label for="overview">Overview</label>
                  <textarea
                    type="text"
                    placeholder="Enter course objectives"
                    id="overview"
                    class="form-control"
                    v-model="course.overview"
                  />
                </div>
                <div class="form-group">
                  <label for="name">Catalogue</label>
                  <multiselect
                    v-model="course.show_in_catalogue"
                    :options="showCatalogue"
                    :close-on-select="true"
                    :allow-empty="true"
                  >
                  </multiselect>
                </div>
                <div class="form-group">
                  <label for="avatar">Avatar</label>
                  <input
                    type="file"
                    placeholder="Enter email"
                    accept="image/*"
                    id="avatar"
                    @change="avatarFile"
                    class="form-control"
                  />
                </div>
                <div class="row justify-content-end">
                  <div class="col-md-3 py-2 align-item-end">
                    <button
                      type="submit"
                      @click.prevent="updateCourse"
                      class="btn btn-success btn-block float-right py-2 mr-1 my-btn"
                    >
                      <span
                        v-if="loading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Save and Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="step == 2">
              <div class="card">
                <div class="card-body">
                  <div class="form-group">
                    <label for="title">Content Type</label>

                    <multiselect
                      v-model="contentType"
                      :options="showType"
                      :close-on-select="true"
                      :allow-empty="true"
                    >
                    </multiselect>
                  </div>
                  <div class="form-group">
                    <label for="title">Name</label>
                    <input
                      type="text"
                      placeholder="Enter Content Name"
                      id="title"
                      class="form-control"
                      v-model="content.unit_name"
                    />
                  </div>
                  <div class="form-group">
                    <label for="overview">Description</label>
                    <textarea
                      type="text"
                      placeholder="Enter Content Description"
                      id="overview"
                      class="form-control"
                      v-model="content.description"
                    />
                  </div>
                  <div class="form-group">
                    <label for="name">Active</label>
                    <div class="d-flex ">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="form-check"
                          v-model="content.active"
                          id="inliner"
                          value="True"
                        />
                        <label class="form-check-label" for="inliner"
                          >True</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="form-check"
                          v-model="content.active"
                          id="inlineRadio2"
                          value="False"
                        />
                        <label class="form-check-label" for="inlineRadio2"
                          >False</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="contentType === 'File'">
                    <label for="avatar">File</label>
                    <input
                      type="file"
                      id="avatar"
                      accept="application/pdf,application/vnd.ms-excel"
                      ref="file"
                      @change="contentFile"
                      class="form-control"
                    />
                  </div>
                  <div
                    class="form-group"
                    v-if="contentType === 'Video' || contentType === 'Article'"
                  >
                    <label for="avatar">Video Url</label>
                    <input
                      type="text"
                      placeholder="Enter video url"
                      id="avatar"
                      v-model="content.url"
                      class="form-control"
                    />
                  </div>
                  <div class="row justify-content-end">
                    <div class="py-2 py-3">
                      <button
                        @click.prevent="updateContent"
                        :disabled="!isContent"
                        class="btn btn-success btn-block my-btn float-right"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
</template>

<script>
/* eslint-disable no-unused-vars */
import toasterMixin from "@/mixins/toasterMixin.js";
import Layout from "../layout/index.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import store from "@/state/store";

export default {
  name: "edit",
  page: {
    title: "Edit Course",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Edit Course",
      items: [
        {
          text: "Entity Admin",
        },
        {
          text: "Edit Course",
          active: true,
        },
      ],
      error: null,
      success: null,
      loading: null,
      entityId: this.$route.params.id,
      totalStep: 2,
      step: 1,
      course: {
        title: "",
        overview: "",
        show_in_catalogue: "",
        avatar: "",
      },
      content: {
        unit_name: "",
        description: "",
        active: "",
        url: "",
        file: "",
      },
      contentType: "",
      courseId: this.$route.params.courseId,
      showType: ["Video", "File", "Article"],
      files: "",
      showCatalogue: ["True", "False"],
    };
  },
  computed: {
    isContent() {
      return (
        this.content.unit_name &&
        this.content.description &&
        this.content.active &&
        this.contentType &&
        (this.content.url || this.content.file.name)
      );
    },
  },
  methods: {
    avatarFile(e) {
      console.log(e.target.value);
      const selectedFile = e.target.files[0];
      this.course.avatar = selectedFile || this.course.avatar;
    },
    contentFile(e) {
      const selectedFile = e.target.files[0];
      this.content.file = selectedFile;
    },
    updateCourse() {
      this.step++;
      this.loading = true;
      this.error = null;
      this.success = null;
      console.log(this.course, "send");

      let formData = new FormData();

      formData.append("entity_id", parseInt(this.entityId));
      formData.append("title", this.course.title);
      formData.append("overview", this.course.overview);
      formData.append(
        "show_in_catalogue",
        Boolean(this.course.show_in_catalogue)
      );

      if (this.course.avatar) {
        formData.append("avatar", this.course.avatar);
      }

      const data = {
        courseID: this.$route.params.courseId,
        course: formData,
      };
      // DISPATCHING DATA TO THE BACKEND
      // store
      //   .dispatch("course/updateCourse", data)
      //   .then((response) => {
      //     if (response.status == 200) {
      //       this.loading = false;
      //       this.toast({
      //         message: "Course Updated",
      //         type: "success",
      //       });
      //       this.$router.replace(
      //         `/entity/${this.entityId}/course/${this.$route.params.courseId}`
      //       );
      //     }
      //   })
      //   .catch((err) => {
      //     if (err?.response?.data == undefined) {
      //       this.loading = false;
      //       this.toast({
      //         message:
      //           "Network error. Check your network connection and try again.",
      //         phone_number: "error",
      //       });
      //     }
      //   });
    },
    updateContent() {
      this.loading = true;
      this.error = null;
      this.success = null;
      let formData = new FormData();

      if (this.contentType === "Video") {
        formData.append("entity_id", parseInt(this.entityId));
        formData.append("unit_name", this.content.unit_name);
        formData.append("description", this.content.description);
        formData.append("active", Boolean(this.content.active));
        formData.append("url", this.content.url);

        const data = {
          content: formData,
          courseId: this.courseId,
          contentType: "video",
        };

        console.log("video");
        // DISPATCHING DATA TO THE BACKEND
        store
          .dispatch("contents/updateContent", data)
          .then((response) => {
            if (response.status == 201) {
              this.loading = false;
              this.toast({ message: "Course created", type: "success" });
              this.$router.push(`/entity/${this.entityId}/course/`);
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.loading = false;
              this.toast({
                message:
                  "Network error. Check your network connection and try again.",
                type: "error",
              });
            }
          });
      } else if (this.contentType == "Article") {
        formData.append("entity_id", parseInt(this.entityId));
        formData.append("unit_name", this.content.unit_name);
        formData.append("description", this.content.description);
        formData.append("active", Boolean(this.content.active));
        formData.append("url", this.content.url);

        const data = {
          content: formData,
          courseId: this.courseId,
          contentType: "article",
        };

        // DISPATCHING DATA TO THE BACKEND
        store
          .dispatch("contents/addNewContent", data)
          .then((response) => {
            if (response.status == 201) {
              this.loading = false;
              this.toast({ message: "Course created", type: "success" });
              this.$router.push(`/entity/${this.entityId}/courses`);
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.loading = false;
              this.toast({
                message:
                  "Network error. Check your network connection and try again.",
                type: "error",
              });
            }
          });
      } else {
        formData.append("entity_id", parseInt(this.entityId));
        formData.append("unit_name", this.content.unit_name);
        formData.append("description", this.content.description);
        formData.append("active", this.content.active);
        formData.append("file", this.content.file);

        console.log(this.content, "file");

        const data = {
          content: formData,
          courseId: this.courseId,
          contentType: "file",
        };
        // DISPATCHING DATA TO THE BACKEND
        store
          .dispatch("contents/addNewContent", data)
          .then((response) => {
            if (response.status == 201) {
              this.loading = false;
              this.toast({ message: "Course created", type: "success" });
              this.$router.push(`/entity/${this.entityId}/courses`);
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.loading = false;
              this.toast({
                message:
                  "Network error. Check your network connection and try again.",
                type: "error",
              });
            }
          });
      }
    },
  },
  created() {
    store.dispatch("course/fetchCourses", this.entityId).then((response) => {
      const courses = response.data.course;

      const course = courses.find(
        (course) => course.id == this.$route.params.courseId
      );
      console.log(course);
      this.course.title = course.title;
      this.course.overview = course.overview;
      this.course.show_in_catalogue = course.show_in_catalogue;
      this.course.avatar = null;
    });
  },
};
</script>

<style scoped>
label {
  color: #000;
}
.table-wrapper {
  background: #fff;
  padding: 20px 25px;
  margin: 30px 0;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
.table-title {
  padding-bottom: 15px;
  background: linear-gradient(40deg, #ff2020, #05ffa3);
  color: #fff;
  padding: 16px 30px;
  margin: -20px -25px 10px;
  border-radius: 1px 1px 0 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
.table-title h2 {
  margin: 5px 0 0;
  font-size: 24px;
}
.my-btn {
  width: 200px;
}
fieldset {
  width: inherit;
  padding: 0 10px;
  border-bottom: none;
}
</style>
